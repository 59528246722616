<template>
  <div class="card card-custom">

    <form
        class="form d-flex align-items-center"
        @submit.prevent="onSubmit($event, form)"
        autocomplete="off"
        novalidate
    >
      <div class="card-body row justify-content-between">
        <div class="alert alert-danger" v-if="errors.error" role="alert">
          <ul class="list-unstyled">
            <li v-for="(item,index) in errors.error" :key="index">{{ item[ 0 ] }}</li>
          </ul>
        </div>
        <input type="hidden" v-model="form.sponsored_by">
        <div class="col-lg-6 col-md-6 mb-4">
          <div class="styleInput">
            <input type="text" autocomplete="off" required id="name" v-model="form.name">
            <label for="name" :title="l('admin:transfer:Adı')"
                :data-title="l('admin:transfer:Adı')"></label>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 mb-4  ">
          <div class="styleInput">
            <input type="text" autocomplete="off" required id="surname" v-model="form.surname">
            <label for="surname" :title="l('admin:transfer:Soyadı')" :data-title="l('admin:transfer:Soyadı')"></label>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 mb-4  ">
          <div class="styleInput">
            <input type="password" autocomplete="off" required id="password" v-model="form.password">
            <label for="password" :title="l('admin:transfer:Şifre')" :data-title="l('admin:transfer:Şifre')"></label>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 mb-4 ">
          <vue-tel-input
              v-if="parameters"
              class="form-control form-control-lg form-control-solid"
              style=" width: 100%; padding: 6px; font-size: 14px; color: #222; box-sizing: border-box; border: 1px solid #ccc; border-radius: 3px;"
              v-model="form.phone"
              :autoDefaultCountry="!!parameters.tum_ulkelere_gsm_izni"
              :default-country="defaultCountry"
              :onlyCountries="!parameters.tum_ulkelere_gsm_izni?['TR']:[]"
              @input="onInput"
              v-bind="bindProps"/>
        </div>
        <div class="col-lg-6 d-flex justify-content-between col-md-6 mb-4 ">
          <div class="row w-100 m-sm-0">
            <div class="styleInput col-12 col-sm-6 ps-sm-0 mb-3">
              <select class="form-select " @change="changeCity(form.city)" v-model="form.city">
                <option value="">{{ l ( 'admin:genel:lutfen_il_seciniz' ) }}</option>
                <option v-for="(item) in cities" :key="item.id" :value="item.id">{{ item.il_adi }}</option>
              </select>
            </div>

            <div class="styleInput col-12 col-sm-6  me-0 pe-sm-0 mb-sm-3">
              <select class="form-select " v-model="form.district" :disabled="!cities.length">
                <option value="">{{ l ( 'admin:genel:lutfen_ilce_seciniz' ) }}</option>
                <option v-for="(item) in district" :key="item.id" :value="item.ilce_adi">{{ item.ilce_adi }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 mb-4  ">
          <div class="styleInput">
            <input type="e-mail" autocomplete="off" id="e-mail" required v-model="form.email">
            <label for="e-mail" :title="l('admin:genel:customer_email')" :data-title="l('admin:genel:customer_email')"></label>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 mb-4  ">
          <div class="styleInput">
            <input type="text" autocomplete="off" id="address" required v-model="form.address">
            <label for="address" :title="l('admin:genel:adres')" :data-title="l('admin:genel:adres')"></label>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 mb-4  ">
          <select class="form-select mb-6" id="bireysel_kurumsal" v-model="form.bireysel_kurumsal">
            <option selected value="bireysel">{{ l ( 'admin:genel:bireysel' ) }}</option>
            <option value="kurumsal">{{ l ( 'admin:genel:kurumsal' ) }}</option>
          </select>
        </div>
        <div class="col-lg-6 col-md-6 mb-4 kurumsal">
          <div class="styleInput">
            <input type="text" autocomplete="off" id="vergi_no" v-model="form.vergi_no">
            <label for="vergi_no" :title="l('admin:languages:languages439')" :data-title="l('admin:languages:languages439')"></label>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 mb-4 kurumsal">
          <div class="styleInput">
            <input type="text" autocomplete="off" id="vergi_dairesi" v-model="form.vergi_dairesi">
            <label for="vergi_dairesi" :title="l('admin:genel:vergi_dairesi')" :data-title="l('admin:genel:vergi_dairesi')"></label>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 mb-4 kurumsal">
          <div class="styleInput">
            <input type="text" autocomplete="off" id="sirket_unvani" v-model="form.sirket_unvani">
            <label for="sirket_unvani" :title="l('admin:transfer:Şirket Ünvanı')" :data-title="l('admin:transfer:Şirket Ünvanı')"></label>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 mb-4 kurumsal">
          <div class="styleInput">
            <input type="text" autocomplete="off"  id="sirket_temsilcisi" v-model="form.sirket_temsilcisi">
            <label for="sirket_temsilcisi" :title="l('admin:transfer:Şirket Temsilcisi')"
                :data-title="l('admin:transfer:Şirket Temsilcisi')"></label>
          </div>
        </div>
        <div>
          <button type="submit" class="btn btn-primary font-weight-bold " ref="submitButton">{{ l ( 'admin:transfer:Kaydet' ) }}</button>
        </div>
      </div>

    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import {
  onMounted,
  computed,
  reactive,
  ref
}                   from 'vue';
import {
  setCurrentPageTitle
}                   from '@/core/helpers/breadcrumb';
import { l }        from '@/core/helpers/lang';

import { useStore } from 'vuex';
import toast        from '@/core/functions/toast';
import { api_url, get_country_api_url }  from '@/ayar';
import get          from '@/core/functions/get';
import post         from '@/core/functions/post';
import 'jquery/dist/jquery.min.js';
import $            from 'jquery';
import {
  VueTelInput
}                   from 'vue3-tel-input';
import 'vue3-tel-input/dist/vue3-tel-input.css';
import ApiService from "@/core/services/ApiService";

export default ( {
  name      : 'YeniBayiKaydi',
  components: {
    VueTelInput,
  },
  data ()
  {
    return {
      cities   : [],
      district : [],
      bindProps: {
        placeholder        : l ( 'admin:languages:langugaes434' ),
        required           : false,
        autocomplete       : 'off',
        name               : 'phone',
        autoFormat         : true,
        customValidate     : true,
        validCharactersOnly: true,
        inputOptions       : {
          showDialCode: false
        }
      },
    };
  },
  methods: {
    onInput ( phone, phoneObject )
    {
      if ( phoneObject )
      {
        if ( phoneObject.formatted )
        {
          this.form.phone = phoneObject.country.dialCode + phoneObject.nationalNumber;
        }
      }
    },
    changeCity ( city )
    {
      post ( api_url + `district`, { 'id': city } ).then ( res =>
      {
        this.district = res.data;
      } );
    }
  },
  setup ()
  {
    const store        = useStore ();
    const submitButton = ref<HTMLElement | null> ( null );
    const errors       = reactive ( {
      error: ''
    } );
    const formData     = {
      sponsored_by     : computed ( () => store.getters.currentUser.sponsor_id ),
      name             : '',
      surname          : '',
      city             : '',
      district         : '',
      email            : '',
      phone            : '',
      password         : '',
      address          : '',
      vergi_no         : '',
      vergi_dairesi    : '',
      sirket_unvani    : '',
      sirket_temsilcisi: '',
      bireysel_kurumsal: 'bireysel',
    };

    const form       = ref ( Object.assign ( {}, formData ) );
    const parameters = computed ( () => store.getters.getParameterList );

    const onSubmit = ( event, values ) =>
    {
      post ( api_url + 'new_bt', values ).then ( ( res ) =>
      {
        if ( res.data.errors )
        {
          errors.error = res.data.errors;
        }
        else
        {
          form.value   = formData;
          errors.error = '';
          toast ( res.data, true );
        }
      } );


    };
    onMounted ( () =>
    {
      setCurrentPageTitle ( l ( 'admin:transfer:Yeni Bayi Kaydı' ) );
      $ ( 'div.kurumsal' ).hide ();
      $ ( 'select[id=\'bireysel_kurumsal\']' ).change ( function ()
      {
        let v = $ ( this ).val ();

        if ( v == 'bireysel' )
        {
          $ ( 'div.kurumsal' ).hide ();
          $ ( '#tc' ).parent ().parent ().show ();
          $ ( '#tc' ).prop ( 'required', true );
          $ ( '#sirket_unvani' ).prop ( 'required', false );
          $ ( '#sirket_temsilcisi' ).prop ( 'required', false );
          $ ( '#vergi_no' ).prop ( 'required', false );
          $ ( '#vergi_dairesi' ).prop ( 'required', false );
          $ ( '#adres' ).prop ( 'required', false );
        }
        else
        {
          $ ( 'div.kurumsal' ).show ();
          $ ( '#tc' ).parent ().parent ().hide ();
          $ ( '#tc' ).prop ( 'required', false );
          $ ( '#sirket_unvani' ).prop ( 'required', true );
          $ ( '#sirket_temsilcisi' ).prop ( 'required', true );
          $ ( '#vergi_no' ).prop ( 'required', true );
          $ ( '#vergi_dairesi' ).prop ( 'required', true );
          $ ( '#adres' ).prop ( 'required', true );
        }
      } );

    } );

    const defaultCountry = ref('tr');

    ApiService.get(get_country_api_url).then(function (payload) {
      defaultCountry.value = payload.data.country_code
    })

    return {
      form,
      errors,
      onSubmit,
      submitButton,
      parameters,
      l
    };
  },
  mounted ()
  {

    get ( api_url + `cities`, {} ).then ( res =>
    {
      this.cities = res.data;
    } );
  }
} );
</script>
<style>
.vti__input {
  background: transparent !important;
}
</style>
<style scoped>

.styleInput {
  position: relative;
}

.styleInput label::before {
  content: attr(title);
  position: absolute;
  top: 0;
  left: 15px;
  line-height: 40px;
  font-size: 13px !important;
  color: #777;
  transition: 300ms all;
}

.styleInput input {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  color: #222;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.styleInput input:focus {
  border-color: #777;
  outline: none;
}

.styleInput input:valid + label::before {
  content: attr(data-title);
  line-height: 20px;
  font-size: 12px;
  top: -10px;
  background-color: #fff;
  padding: 0 6px;
  left: 6px;
}

.styleInput input:focus + label::before {
  content: attr(data-title) !important;
  line-height: 20px;
  font-size: 12px;
  top: -20px;
  background-color: #fff;
  padding: 8px;
  left: 6px;
  color: #777;
}


.col-lg-6 #field .input-group input:focus + label::before {
  left: 60px !important;
  z-index: 99;
  top: 25px;

}

.col-lg-6 #field .input-group #tag::before {
  content: attr(title);
  position: absolute;
  top: 25px;
  left: 30px !important;
  line-height: 40px;
  font-size: 11px !important;
  color: #777;
  transition: 300ms all;
}


@media only screend and (max-width: 900px) {
  .styleInput {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;


  }

  .styleInput input {
    display: inline-block;
  }
}

.country-phone-input .dropdown:focus {
  outline: none;
  color: grey;
}
</style>